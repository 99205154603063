<template>
  <van-tabbar route active-color="#34a53c">
    <van-tabbar-item icon="wap-home" to="/geom/">实时</van-tabbar-item>
    <van-tabbar-item icon="card" to="/geom/statistics">统计</van-tabbar-item>
    <van-tabbar-item icon="setting" to="/geom/history">历史</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabbar',
}
</script>
