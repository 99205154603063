<template>
  <div>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="筛选条件" class="filter">
           <van-cell title="选择设备查询" is-link @click="filter.dev.show = true"/>
           <van-cell title="请选择开始时间" :value="filter.time.startVal.toString()" is-link @click="filter.time.startShow = true"/>
           <van-cell title="请选择结束时间" :value="filter.time.endVal" is-link @click="filter.time.endShow = true"/>
           <van-row class="text-center">
              <van-col span="12"><van-button type="primary" size="small" @click="resetData">重置</van-button></van-col>
              <van-col span="12"> <van-button type="primary" size="small" @click="filterData">筛选</van-button></van-col>
           </van-row>
      </van-collapse-item>
    </van-collapse>
    <div class="chart-content">
         <div v-for="(item,index) in mockData" :key="index">
            <div class="title"><i class="fa fa-area-chart"></i>智慧车位-{{item.name}}</div>
            <div v-if="loading">
                <van-loading type="spinner" vertical style="margin:4rem 0">加载中</van-loading>
            </div>
            <div v-else>
                <div :id="'chart'+index"></div>
            </div>
        </div>
    </div>
    <!-- 筛选设备 -->
    <van-popup v-model="filter.dev.show" round position="bottom"  :style="{ height: '45%' }">
        <div class="filter-dev-area">
            <van-checkbox-group v-model="filter.dev.value" @change="changeCheckout">
                <van-cell-group>
                    <van-cell
                    v-for="(item, index) in filter.dev.columns"
                    clickable
                    :key="item.devCode"
                    :title="item.devName"
                    @click="toggle(index)"
                    >
                    <template #right-icon>
                        <van-checkbox :name="item.devCode" ref="checkboxes" checked-color="#34a53c" />
                    </template>
                    </van-cell>
                </van-cell-group>
            </van-checkbox-group>
        </div>
    </van-popup>
    <!-- 筛选时间段 -->
    <van-popup v-model="filter.time.startShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.time.start"
        type="datetime"
        title="选择开始时间"
        @confirm="confromStartTime"
        @cancel="filter.time.startShow = false"
      />
    </van-popup>
    <van-popup v-model="filter.time.endShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.time.end"
        type="datetime"
        title="选择结束时间"
        @confirm="confromEndTime"
        @cancel="filter.time.endShow = false"
      />
    </van-popup>
    <tabbar />
  </div>
</template>

<script>
// 日期格式化
Date.prototype.Format = function (fmt) {
  var o = {
  "M+": this.getMonth() + 1, //月份
  "d+": this.getDate(), //日
  "H+": this.getHours(), //小时
  "m+": this.getMinutes(), //分
  "s+": this.getSeconds(), //秒
  "q+": Math.floor((this.getMonth() + 3) / 3), //季度
  "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
  if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
const defaultToday = new Date().Format("yyyy-MM-dd");
import { Chart } from '@antv/g2';
import tabbar from '@/components/geom/tabBar.vue'
export default {
  components: {
    tabbar
  },
  data() {
    return {
      activeName: '',
      loading:true,
      filter:{
        dev:{
          value:[],
          show:false,
          columns:[]
        },
        time:{
          start:new Date(),
          end:new Date(),
          startVal:'',
          endVal:'',
          startShow:false,
          endShow:false,
        }
      },
      mockData:[],
      chart:[]
    }
  },
  methods:{
    // 初始化日期
    initDate(){
      this.filter.time.start = new Date((`${defaultToday} 00:00:00`).replace(/-/g, "/"));  // 修复ios date报错
      this.filter.time.end = new Date();
      this.filter.time.startVal = this.filter.time.start.Format("yyyy-MM-dd HH:mm:ss");
      this.filter.time.endVal = this.filter.time.end.Format("yyyy-MM-dd HH:mm:ss");
    },
    // 确认开始时间
    confromStartTime(value){
      this.filter.time.startVal = value.Format("yyyy-MM-dd HH:mm:ss");
      this.filter.time.startShow = false;
    },
    // 确认结束时间
    confromEndTime(value){
      this.filter.time.endVal = value.Format("yyyy-MM-dd HH:mm:ss");
      this.filter.time.endShow = false;
    },
    // // 格式化日期显示
    // formatDate(date) {
      // return `${date.getFullYear().toString()}-${this.fillZero(date.getMonth() + 1)}-${this.fillZero(date.getDate())}`;
    // },
    // // 日期补零
    // fillZero(str) {
    //     let realNum;
    //     str < 10 ? realNum = '0' + str : realNum = str;
    //     return realNum;
    // },
    changeCheckout(val){
      if(val.length>4){
        this.filter.dev.value.pop();
        return this.$toast('最多不能超过4个')
      }
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    // 重置
    resetData(){
      this.filter.dev.value = [];
      this.initDate();
    },
    // 筛选
    filterData(){
      if(this.filter.dev.value.length == 0){
        return this.$toast('请勾选设备，设备不能为空')
      }
      this.$nextTick(()=>{
        if(this.chart.length){
          this.chart.forEach( e => {
            e.destroy()
          })
        }
      })
      this.loading = true;
      this.activeName = '';
      this.getChartInfo();
    },
    // 获取数据
    getChartInfo(){
       this.$api.GEOM.getDateCharts({
         projectCode:this.projectCode,
         devCode:this.filter.dev.value.length >0 ? this.filter.dev.value.join(',') : void 0,
         beginTime:this.filter.time.startVal ? this.filter.time.startVal : void 0,
         endTime:this.filter.time.endVal ? this.filter.time.endVal : void 0,
       }).then( data =>{
          this.mockData = data;
          this.showChart();
       })
    },
    // 绘制数据
    showChart(){
      this.loading = false;
      this.$nextTick(()=>{
          for(let i=0;i<this.mockData.length;i++){
            const data = this.mockData[i].data;
            this.chart[i] = new Chart({
              container: `chart${i}`,
              autoFit: true,
              height: 200,
              padding: [30, 30, 45, 50],
            });
            this.chart[i].data(data);
            this.chart[i].scale('month', {
              type:'time',
              mask:'MM-DD HH:mm',
              range: [0, 1],
            });
            this.chart[i].scale('value', {
              nice: true,
              tickCount:2,
              min:0,
              max:1,
              formatter:(v)=>{
                return v ? '有车' : '无车'
              }
            });

            // this.chart[i].axis('value', {
            //   line:{
            //     style:{
            //       stroke:'#cccccc'
            //     }
            //   },
            //   tickLine:{}
            // });

            this.chart[i]
            .line()
            .position('month*value').tooltip('month*value',  (month,value) => { 
                return {
                    name: '停车状态',
                    value: value === 1 ? '有车' : '无车'
                }
            })
            .shape('hv');
            this.chart[i].theme({ "styleSheet": { "brandColor": "#34a53c", "paletteQualitative10": ["#34a53c"] } });
            this.chart[i].render();
          }
      })
    },
    // 获取设备信息
    getDevInfo(){
      this.$api.GEOM.allDevInfo({
         projectCode:this.projectCode
      }).then( d => {
        this.filter.dev.columns = d;
        // 默认4个设备
        this.defaultSelectDev = d.slice(0,4);
        this.filter.dev.value = this.defaultSelectDev.map( v => v.devCode);
        this.getChartInfo();
      })
    }
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.initDate();
      this.getDevInfo();
  }
}
</script>
<style lang="scss" scoped>
.filter{text-align: left;}
.text-center{
  text-align: center;
  button{
    padding: 0 20px;
    margin-top: 5%;
  }
}
.filter-dev-area{
    text-align: left;
    // padding: 20px;
    margin: 20px 30px;
    overflow-y: scroll;
}
#airTemperatureChart,
#airHumidnessChart,
#roadTemperatureChart,
#visibilityChart{width: 100%;height: 18rem;}
.title{
  text-align: left;font-size: .95rem;line-height:2;padding-left: 4%;
  .fa{color: #34a53c;font-size: 1.2rem;padding-right: 2%;}
}
.chart-content{
    padding-top: 10px;
    padding-bottom: 22%;
}
</style>